import React, { Suspense, useEffect, useContext, useCallback } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import Layout from '../components/Layout'
import PropTypes from 'prop-types'
import { createBrowserHistory } from "history"
import AAD_B2CService from '../settings/azure'
import Loading from '../components/Loading'
import * as containers from './index'
import { StoreContext } from '../settings/context'

const hist = createBrowserHistory()

class AppClass extends React.Component {
  static contextType = StoreContext
  static propTypes = {
    customer: PropTypes.string,
    user: PropTypes.string,
    handleLogout: PropTypes.func,
    handleCloseSnackbar: PropTypes.func,
    state: PropTypes.object,
  }

  constructor() {
    super();
    this.state = {
      listMenu: [],
      loading: false
    };
  }

  componentDidMount() {
    const contextData = this.context
    this.setState({ loading: true })
    fetch(`${process.env.REACT_APP_BACKEND}${process.env.REACT_APP_BACKEND_PREFIX}/Admin/` + this.props.user + "/Rutas web", {
      method: "GET",
      headers: {"Origin": `${process.env.REACT_APP_ORIGIN}`}
    })
      .then(res => res.json())
      .then((data) => {
        const listMenu = data.map((item, index) => {
          let menu = JSON.parse(item.component)
          menu.component = containers[menu.component]
          if (data.length === 1 && index === 0 && menu.path !== "/") {
            menu.path = "/"
          }
          if (data.length > 0 && index === 0 && menu.path !== "/") {
            menu.path = "/"
            menu.menuExact = true
          }
          return menu
        })
        this.setState({ listMenu: listMenu })
        this.setState({ loading: false })
      })
      .catch(error => {
        console.error('Error:', error)
        this.setState({ loading: false })
      })

    fetch(`${process.env.REACT_APP_BACKEND}${process.env.REACT_APP_BACKEND_PREFIX}/UserITS/ListRoleByLogin/` +
      this.props.user, {
        method: "GET",
        headers: {"Origin": `${process.env.REACT_APP_ORIGIN}`}
      })
      .then(res => res.json())
      .then((response) => {
        contextData.setRole(response)
      })
      .catch(error => {
        console.error('Error:', error)
      })

    fetch(`${process.env.REACT_APP_BACKEND}${process.env.REACT_APP_BACKEND_PREFIX}/UserITS/ListCommerceByLogin/` +
      this.props.user, {
        method: "GET",
        headers: {"Origin": `${process.env.REACT_APP_ORIGIN}`}
      })
      .then(res => res.json())
      .then((response) => {
        contextData.setCommerce(response)
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }
  render() {
    const { listMenu } = this.state
    //if (this.state.loading) return <Loading />
    return (
      <>
        {this.state.loading &&
          <Loading />
        }
        <Router history={hist}>
          <Layout
            listMenu={listMenu.filter(item => !item.hideSidebar)}
            handleLogout={this.props.handleLogout}
            handleCloseSnackbar={this.props.handleCloseSnackbar}
            snackbar={this.props.state.snackbar}
            user={this.props.user}
            customer={this.props.customer}
          >
            <Suspense fallback={<Loading />}>
              <Switch>
                {listMenu.map((item, index) => (
                  <Route key={index} exact={item.exact} path={item.path} component={item.component} />
                ))}
                <Route key={-1} exact={true} path="/tickets/:id" component={containers["Tickets"]} />
                <Route component={containers.NoMatch} />
              </Switch>
            </Suspense>
          </Layout>
        </Router>
      </>
    )
  }
}

function App() {
  const { state, dispatch, setLogin, setCustomer } = useContext(StoreContext)
  const AAD_B2CServiceI = new AAD_B2CService()

  const didMount = async () => {
    const user = AAD_B2CServiceI.getUser()
    setLogin(JSON.stringify(user))
    setCustomer(AAD_B2CServiceI.getCustomer())
  }

  useEffect(() => { didMount() }, [])
  const handleLogout = useCallback(() => { AAD_B2CServiceI.logout() })
  const handleCloseSnackbar = useCallback(() => { dispatch({ type: 'snackClose' }) })

  return (
    <AppClass user={AAD_B2CServiceI.getUser().emails}
      customer={AAD_B2CServiceI.getCustomer()}
      state={state}
      handleLogout={handleLogout}
      handleCloseSnackbar={handleCloseSnackbar}
    />
  );
}

export default App;
import 'bootstrap/dist/css/bootstrap.css'
import './index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from "react-router-dom"
import registerServiceWorker from './registerServiceWorker'
import App from './containers/App'
import { MuiThemeProvider } from '@material-ui/core/styles'
import authentication from '@kdpw/msal-b2c-react'
import { config } from "./settings/azure"
import theme from './settings/theme'
import ErrorBoundary from './components/ErrorBoundary '
import StoreProvider from './settings/context'

const rootElement = document.getElementById('root')
authentication.initialize(config())

authentication.run(() => {
  ReactDOM.render(
    <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <StoreProvider>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </StoreProvider>
        </MuiThemeProvider>
    </BrowserRouter>,
    rootElement);

  registerServiceWorker()
})

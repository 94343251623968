import { lazy } from 'react'

export const Dashboard = lazy(() => import("./dashboard"))
export const Admin = lazy(() => import("./admin"))
export const Commerce = lazy(() => import("./commerce"))
export const AddCommerce = lazy(() => import("./commerce/Add"))
export const EditCommerce = lazy(() => import("./commerce/Edit"))
export const Mpos = lazy(() => import("./mpos"))
export const AddMpos = lazy(() => import("./mpos/Add"))
export const EditMpos = lazy(() => import("./mpos/Edit"))
export const Device = lazy(() => import("./device"))
export const AddDevice = lazy(() => import("./device/Add"))
export const EditDevice = lazy(() => import("./device/Edit"))
export const Users = lazy(() => import("./users"))
export const AddUser = lazy(() => import("./users/Add"))
export const EditUser = lazy(() => import("./users/Edit"))
export const Reports = lazy(() => import("./reports"))
export const Collection = lazy(() => import("./collection"))
// export const ParamsReport = lazy(() => import("./reports/ParamsReport"))
export const Roles = lazy(() => import("./admin/Roles"))
export const RolesDetail = lazy(() => import("./admin/RolDetail"))
export const AdminReports = lazy(() => import("./admin/Reports"))
export const EconomicActivity = lazy(() => import("./admin/EconomicActivity"))
export const BulkCommerces = lazy(() => import("./admin/BulkCommerces"))
export const BulkBines = lazy(() => import("./admin/BulkBines"))
export const Parameters = lazy(() => import("./admin/Parameters"))
export const Fares = lazy(() => import("./admin/Fares"))
export const NoMatch = lazy(() => import("../components/NoMatch"))
export const Home = lazy(() => import("./home"))
export const Transactions = lazy(() => import("./transactions"))
export const TransactionsConsole = lazy(() => import("./transactionsConsole"))
export const Tickets =lazy(() => import("./tickets"))
export const Logs =lazy(() => import("./admin/Logs"))
export const Jobs =lazy(() => import("./admin/Jobs"))
export const Company =lazy(() => import("./company"))
export const Validators =lazy(() => import("./validators"))
export const Buses =lazy(() => import("./buses"))
export const Passengers =lazy(() => import("./passengers"))
export const BlackList =lazy(() => import("./blacklist"))
export const Route =lazy(() => import("./route"))
export const Drivers =lazy(() => import("./drivers"))
export const Conciliation =lazy(() => import("./reports/Conciliation"))
export const CashConciliation =lazy(() => import("./reports/CashConciliation"))
export const Cashier =lazy(() => import("./reports/Cashier"))
export const BLPassenger = lazy(() => import("./reports/BLPassenger"))
export const Transaction = lazy(() => import("./reports/Transaction"))
export const BLMaskedPan = lazy(() => import("./reports/BLMaskedPan"))
export const Geofencing =lazy(() => import("./geofencing"))
export const Itinerary =lazy(() => import("./itinerary"))

//TODO: Agregar opción para ver las transacciones
